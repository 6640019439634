import { css } from "@emotion/react"
import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Buttons from "../../components/common/button"
import CarouselComponent from "../../components/common/carousel-component"
import CTA from "../../components/common/CTA"
import Frame from "../../components/common/frame"
import PopUpForm from "../../components/common/PopUpForm"
import SVGIcon from "../../components/common/SVGIcon"
import Navigation from "../../components/navigation"
import Slide from "../../components/utility/slide_content"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pages/resellers.scss"
import "../../styles/pages/roadmap.scss"
import "../../styles/pages/tech-partnership.scss"

const Resellers = () => {
  const [popupVisibility, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a name",
    },
    {
      name: "jobtitle",
      type: "text",
      label: "Job title",
      errorMessage: "Please enter a job title",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      errorMessage: "Please enter a company name",
    },

    {
      name: "mobilephone",
      type: "phone",
      label: "Mobile number",
      errorMessage: "Please enter a mobile number",
    },
    { name: "partnership_type", type: "hidden", label: "Partnership", value: "Resellers" },
  ]

  const responsiveSpeaker = {
    mobile2: {
      breakpoint: { max: 1175, min: 900 },
      items: 2,
    },
    mobile3: {
      breakpoint: { max: 900, min: 425 },
      items: 1.4,
    },
    mobile4: {
      breakpoint: { max: 425, min: 0 },
      items: 1,
    },
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query resellers {
            SuperOps {
              pages(where: { title: "partners - resellers" }) {
                title
                subtitle
                seo {
                  title
                  description
                  image {
                    url
                  }
                }
                newField {
                  ... on SuperOps_Hero {
                    tag
                    backgroundColor
                    heroHeading: heading {
                      text
                      html
                    }
                    content {
                      text
                    }
                    primaryButtonText
                    primaryButtonLink
                  }
                  ... on SuperOps_Card {
                    __typename
                    cardName
                    title
                    heading
                    text
                    subtext
                    image {
                      url
                    }
                    color {
                      hex
                    }
                  }
                  ... on SuperOps_CtaFeature {
                    __typename
                    ctaBox {
                      type
                      theme
                      customBackgroundColor
                      heading {
                        html
                      }
                      description {
                        html
                      }
                      primaryButtonText
                      primaryButtonLink
                      secondaryButtonText
                      secondaryButtonLink
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { seo, newField, subtitle } = page
              const hero = newField[0]
              const WhyPartner = newField[1]
              const Steps = newField[2]
              let navBgColor = hero.backgroundColor
              navBgColor = navBgColor.slice(
                navBgColor.indexOf("#"),
                navBgColor.indexOf("#") + 7
              )

              return (
                <div className="tech-partner resellers">
                  <Frame seo={seo} newButton>
                    <header>
                      <Navigation
                        color={navBgColor}
                        page="About us"
                        dskScroll={450}
                        newButton
                      />
                    </header>

                    <div>
                      <PopUpForm
                        visibility={popupVisibility}
                        togglePopup={val => {
                          setPopupVisibility(val)
                        }}
                        formData={formdata}
                        endpoint={process.env.HUBSPOT_PARTNERSHIP_ENDPOINT}
                        url={process.env.HUBSPOT_RESELLERS_URL}
                        formName="Channel partnerships form - Superops"
                        formTitle="Become a SuperOps Resellers"
                        successMsg="Submitted successfully!"
                        additionalFormProps={{ IpStackData: true }}
                      />
                    </div>

                    <section
                      className="hero text-center"
                      css={css`
                        background: ${hero.backgroundColor};
                      `}
                    >
                      <Container className="Layout-container position-relative">
                        <SVGIcon
                          name="leftHand"
                          className="position-absolute left-image d-dsk down-to-up-3"
                        />
                        <SVGIcon
                          name="rightHand"
                          className="position-absolute right-image d-dsk down-to-up-3"
                        />
                        <h1 className="heading mx-auto fw-bold down-to-up-1">
                          {hero.heroHeading.text}
                        </h1>
                        <div className="p18 down-to-up-2">
                          <p className="description mx-auto">
                            {hero.content.text}
                          </p>
                        </div>
                        <Buttons
                          theme="primary-new mb50 down-to-up-3"
                          arrow
                          onClick={() => togglePopup()}
                          text={hero.primaryButtonText}
                        />{" "}
                      </Container>
                    </section>

                    <section className="why-partner">
                      <Container className="Layout-container">
                        <h2 className="font-roboto fw-bold text-center mb50 down-to-up-4">
                          {WhyPartner.cardName}
                        </h2>
                        <Row>
                          {WhyPartner.title.map((item, idx) => {
                            return (
                              <Col lg={4}>
                                <div className="d-flex align-items-center gap-8 mb16 down-to-up-5">
                                  <div className="img-wrapper">
                                    <img
                                      src={WhyPartner.image[idx].url}
                                      alt={item}
                                      className="w-100"
                                    />
                                  </div>
                                  <p className="p16 fw-bold m-0">{item}</p>
                                </div>
                                <p className="p14 description mb30">
                                  {WhyPartner.subtext[idx]}
                                </p>
                              </Col>
                            )
                          })}
                        </Row>
                      </Container>
                    </section>

                    <section className="steps">
                      <Container className="Layout-container">
                        <Slide delay="200">
                          <h2 className="font-roboto fw-bold text-center mb50">
                            {Steps.cardName}
                          </h2>
                        </Slide>
                        <Slide delay="200">
                          <div className="parent-box d-flex justify-content-between d-dsk">
                            {Steps.title.map((item, id) => {
                              return (
                                <div
                                  className="box br10"
                                  style={{
                                    backgroundColor: `${Steps.color[id].hex}`,
                                  }}
                                >
                                  <p className="text-pink fw-600 p12 let-space-2 m-0 mb8">
                                    {Steps.heading[0]}
                                  </p>
                                  <p className="number fw-bold m-0 mb16">
                                    {"0" + (id + 1)}
                                  </p>
                                  <p className="p16 fw-600 m-0">{item}</p>
                                </div>
                              )
                            })}
                          </div>
                        </Slide>
                        <Row className="d-mob mb100">
                          <Slide delay="200">
                            <CarouselComponent
                              status
                              swipeable
                              responsive={responsiveSpeaker}
                              autoPlay={false}
                            >
                              {Steps.title.map((item, id) => {
                                return (
                                  <div
                                    className="box br10"
                                    style={{
                                      backgroundColor: `${Steps.color[id].hex}`,
                                    }}
                                  >
                                    <p className="text-pink fw-600 p12 let-space-2 m-0 mb8">
                                      {Steps.heading[0]}
                                    </p>
                                    <p className="number fw-bold m-0 mb16">
                                      {"0" + (id + 1)}
                                    </p>
                                    <p className="p16 fw-600 m-0 description">
                                      {item}
                                    </p>
                                  </div>
                                )
                              })}
                            </CarouselComponent>
                          </Slide>
                        </Row>
                      </Container>
                    </section>

                    <section>
                      <Slide delay="200">
                        <CTA
                          data={[newField[3].ctaBox]}
                          className="Layout-container"
                          maxHeadingWidth="600px"
                          lgLeft={7}
                          lgRight={4}
                          rightStyles={{ alignItems: "center" }}
                          newButton
                          newDesign
                          openPopUp={() => togglePopup()}
                        />
                      </Slide>
                    </section>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default Resellers
